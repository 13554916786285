import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";


function Tarifas() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Tarifas
            </h3>
            <Row className="justify-content-center">
              <Col md="2">
                <center className="mt-5">
                  <a href={require("assets/pdf/00585-TARIFAS NOTARIALES 2025 SDN.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                    <img
                      alt="Imagen con referencia Tarifas Registrales"
                      width="80px"
                      src={require("assets/img/sections/pdf.jpg")}
                    ></img>
                    <h6 className="my-3">TARIFAS REGISTRALES</h6>
                  </a>
                </center>
              </Col>
              <Col md="2">
                <center className="mt-5">
                  <a href={require("assets/pdf/00179-TARIFAS 2025  DTR SDR.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                    <img
                      alt="Imagen con referencia Tarifas Notariales"
                      width="80px"
                      src={require("assets/img/sections/pdf.jpg")}
                    ></img>
                    <h6 className="my-3">TARIFAS DTR SDR</h6>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Tarifas;
